var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"fixed-header":"","show-select":"","headers":_vm.computedHeaders,"items":_vm.companies,"options":_vm.options,"footer-props":_vm.footerProps,"custom-sort":_vm.customSort,"item-key":"company_name"},on:{"update:options":function($event){_vm.options=$event},"update:footerProps":function($event){_vm.footerProps=$event},"update:footer-props":function($event){_vm.footerProps=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_vm._t("top")]},proxy:true},{key:"header.data-table-select",fn:function(ref){return [_c('select-all-header-cell',{on:{"click:item":function($event){return _vm.$emit('multiselect', $event.value)}}})]}},{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}})]}},{key:"item.company_name",fn:function(ref){
var item = ref.item;
return [_vm._v("\n    "+_vm._s(item.company_name)+"\n  ")]}},{key:"item.bank_name",fn:function(ref){
var item = ref.item;
return [_vm._v("\n    "+_vm._s(item.bank_name)),_c('br'),(item.bank_code)?_c('span',{staticClass:"fw-normal"},[_vm._v("("+_vm._s(item.bank_code)+")")]):_vm._e()]}},{key:"item.bank_branch_name",fn:function(ref){
var item = ref.item;
return [_vm._v("\n    "+_vm._s(item.bank_branch_name)),_c('br'),(item.bank_branch_code)?_c('span',{staticClass:"fw-normal"},[_vm._v("("+_vm._s(item.bank_branch_code)+")")]):_vm._e()]}},{key:"item.user_bank",fn:function(ref){
var item = ref.item;
return [_vm._v("\n    "+_vm._s(item.user_bank.simpletext)+"\n  ")]}},{key:"item.paid_by_customer",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(item.paid_by_customer),expression:"item.paid_by_customer"}],attrs:{"label":"","x-small":"","dark":"","color":"blue"},domProps:{"textContent":_vm._s('先方')}})]}},{key:"item.gensenzei",fn:function(ref){
var item = ref.item;
return [_c('s-chip-dark',{directives:[{name:"show",rawName:"v-show",value:(item.gensenzei),expression:"item.gensenzei"}],staticClass:"px-3",attrs:{"color":"blue-500"}},[_vm._v("\n      源泉\n    ")])]}},{key:"item.journals",fn:function(ref){
var item = ref.item;
return [_c('journal-cell',{attrs:{"items":item.company_pattern.company_journals,"show-all":item.showAll,"definition":_vm.definition},on:{"click:show-all":function($event){item.showAll = !item.showAll}}})]}},{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"mt-3",attrs:{"value":true,"color":"yellow","icon":"warning"}},[_vm._v("\n      "+_vm._s(_vm.$t('message.noResult'))+"\n    ")])]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }