import CompaniesByFreee from '@/clients/freee'

export const freeeCompanyService = {
  async get(token) {
    const companies = await CompaniesByFreee.get(token)
    if (companies.length) {
      return companies.map((company) => {
        company.selected = true
        company.display = true
        return company
      })
    } else {
      throw new Error('作成可能な学習データがありません。')
    }
  },
}
