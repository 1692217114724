<template>
  <v-container fluid pa-0>
    <v-dialog v-model="dialog.checkOverwrite" max-width="650">
      <v-card class="sweeep-dialog">
        <v-card-title>{{ $t('message.confirm') }}</v-card-title>
        <v-card-text
          >現在sweeepに存在するマスタデータを削除し、freeeのマスタデータに置換えますか？</v-card-text
        >
        <v-card-actions>
          <v-spacer />
          <v-btn
            v-tooltip="'sweeepに登録済みのデータは削除されません'"
            :disabled="loading.fetchDataReplace"
            :loading="loading.fetchData"
            @click="fetchData(false)"
          >
            削除せず追加する
          </v-btn>
          <v-btn
            v-tooltip="
              'sweeepに登録済みのデータは削除され、freeeのデータのみが登録された状態になります'
            "
            color="primary"
            :disabled="loading.fetchData"
            :loading="loading.fetchDataReplace"
            @click="$refs.dialogConfirmDeleteMaster.open()"
          >
            削除し置き換える
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <input-delete-dialog
      ref="dialogConfirmDeleteMaster"
      :title="$t('message.freee_confirm_delete_master_title')"
      :message="$t('message.freee_confirm_delete_master_message')"
      :cancel-text="$t('message.modal_cancel_btn')"
      :delete-text="$t('message.continue')"
      @submit="
        fetchData(true)
        $refs.dialogConfirmDeleteMaster.close()
      "
    />

    <import-widget
      :companies="companiesFromFreee"
      :show="dialog.importWidget"
      :loading="loading.importWidget"
      import-from-freee
      @change:show="dialog.importWidget = $event"
      @click:import="createCompaniesWithFreeeData($event)"
    />

    <div
      v-if="loading.page"
      class="app-flex justify-center align-center h-vh-100"
    >
      <v-progress-circular indeterminate color="primary" />
    </div>
    <v-form
      v-else
      ref="form"
      class="pa-4"
      lazy-validation
      style="max-width: 680px"
    >
      <v-card class="sweeep-dialog">
        <v-card-title> freee 連携 </v-card-title>
        <v-card-text class="pa-4 justify-center">
          <v-layout wrap>
            <v-flex xs12>
              <div class="fs-14 mb-3 fw-bold">1. freeeへログイン</div>
              <div class="fs-12 mb-3 px-3">
                freeeと連携する場合はスイッチをOnにしてください。<br />freeeと連携すると各種マスタの取込みや仕訳連携ができます。
              </div>
              <v-switch
                :input-value="freee.authorized"
                class="mb-4 px-3"
                color="primary"
                hide-details
                label="freee連携"
                style="width: 250px"
                @change="switchFreeeConnection($event)"
              />
            </v-flex>
            <v-flex v-if="freee.authorized" xs12>
              <div class="fs-14 my-3 fw-bold">2. 事業所を選択</div>
              <v-select
                :value="freee.companyId"
                class="mb-2 px-3"
                item-text="display_name"
                item-value="id"
                label="連携事業所"
                placeholder=" "
                validate-on-blur
                :items="freee.companyList"
                outlined
                dense
                filled
                @input="registerDefaultCompany($event)"
              />
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>

      <v-card v-show="displayMasterArea" class="sweeep-dialog mt-5">
        <v-card-title> マスタ同期 </v-card-title>
        <v-card-text class="pa-4 justify-center">
          <v-layout wrap>
            <div class="fs-12 my-4 px-3">
              マスタを同期する場合は管理項目を指定して同期ボタンを押してください。
            </div>
            <template v-for="field in journalFields">
              <v-flex
                v-if="displaySetting[field] && field in checkbox"
                :key="field"
                xs6
                class="px-3"
              >
                <v-checkbox
                  v-model="checkbox[field]"
                  class="small-checkbox mb-2"
                  color="primary"
                  hide-details
                  :label="definition[field]"
                  :disabled="disableCheckbox"
                />
              </v-flex>
            </template>
            <v-flex xs12 class="mt-3">
              <v-btn
                class="mb-2"
                color="primary"
                :disabled="disableFetch"
                :loading="loading.fetchData"
                style="text-transform: lowercase"
                @click="activateGetMasterDialog()"
              >
                freee からマスタデータを取得して同期
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
      <v-card v-show="displayMasterArea" class="sweeep-dialog mt-5">
        <v-card-title> 学習データ作成 </v-card-title>
        <v-card-text class="pa-4 justify-center">
          <v-layout wrap>
            <v-flex>
              <div class="fs-12 my-4 px-3">
                <p>
                  freeeに登録されている過去の取引から学習データを作成します。
                </p>
                <p>
                  すでにsweeep内で学習データが存在している場合かつプロフィール情報（会社名、電話番号）が一致する場合はsweeepに取り込まれません。（上書き防止のため）
                </p>
              </div>
            </v-flex>
            <v-flex xs12>
              <v-btn
                class="mb-2"
                color="primary"
                :loading="loading.learn"
                :disabled="!freee.companyId"
                style="text-transform: lowercase"
                @click="fetchCompaniesWithFreeeData()"
              >
                学習データ作成
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
      <v-card v-show="displayMasterArea" class="sweeep-dialog mt-5">
        <v-card-title> 仕訳同期方法 </v-card-title>
        <v-card-text class="pa-4 justify-center">
          <v-layout wrap>
            <v-flex>
              <div class="fs-12 my-4 px-3">
                <p>freeeへの仕訳同期方法を選択します。</p>
              </div>
            </v-flex>
            <v-flex xs12>
              <v-radio-group
                v-model="freee.separateApproval"
                class="px-3 mt-0"
                @change="changeApproveType($event)"
              >
                <v-radio
                  color="primary"
                  label="仕訳最終承認時にfreeeと同期します。"
                  :value="false"
                />
                <v-radio
                  color="primary"
                  label="仕訳最終承認後、freee連携ボタンを押して同期します。"
                  :value="true"
                />
              </v-radio-group>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import ImportWidget from '@/pages/master/Company/@components/ImportWidget/ImportWidget'

import Snackbar from 'Helpers/snackbar/index'
import Axios from 'axios'
import { mapGetters } from 'vuex'
import { journalFields } from 'Helpers/journal'
import { freeeCompanyService } from 'Helpers/freee'
import { companyBatchService } from '@/services/company/company'
import { convertForImportWidget } from 'Helpers/company'

export default {
  components: {
    ImportWidget,
  },
  props: {
    getJournalItems: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      journalFields,
      checkbox: {
        account_title: false,
        department: false,
        item: false,
        segment: false,
        tag: false,
        tax: false,
        vendor: false,
        walletable: false,
      },
      dialog: {
        checkOverwrite: false,
        importWidget: false,
      },
      freee: {
        authorized: false,
        companyId: '',
        companyList: [],
        tokenExists: false,
        separateApproval: false,
      },
      loading: {
        getAuthorization: false,
        fetchData: false,
        fetchDataReplace: false,
        page: false,
        learn: false,
        importWidget: false,
      },
      companiesFromFreee: [],
    }
  },
  computed: {
    ...mapGetters(['displaySetting', 'definition']),
    disableCheckbox: function () {
      if (!!this.freee.companyId && this.loading.fetchData == false) {
        return false
      }
      return true
    },
    displayMasterArea() {
      return this.freee.authorized
    },
    disableFetch: function () {
      return !Object.keys(this.checkbox).some((key) => this.checkbox[key])
    },
  },
  created() {
    this.loading.page = true
    this.checkFreeeTokenCompany()
  },
  methods: {
    registerDefaultCompany(companyId) {
      this.freee.companyId = companyId
      this.$store.dispatch('postFreeeCompany', companyId).then((success) => {
        if (success) {
          Snackbar.success('デフォルト事業所の登録に成功しました')
        }
      })
    },
    checkFreeeTokenCompany() {
      this.$store.dispatch('getFreeeAccessToken').then((freee) => {
        if (!freee) {
          this.freee.tokenExists = false
          this.checkAuthorization()
        } else {
          this.freee.authorized = true
          this.freee.tokenExists = true
          this.freee.companyId = freee.companyId
          this.freee.separateApproval = freee.separateApproval
          this.checkToken()
          if (!freee.companyId) {
            Snackbar.warning('連携事業所を設定してください。')
          }
        }
      })
    },
    checkAuthorization() {
      if (this.freee.tokenExists) {
        this.checkToken()
        return
      }
      if (this.$route.query.code === undefined) {
        this.loading.page = false
        return
      }
      const data = {
        code: this.$route.query.code,
        state: this.$route.query.state,
      }
      Axios.post(this.$store.getters.apiFreeeTokenUrl, data, {
        headers: { Authorization: this.$store.getters.getAuthToken },
      })
        .then(() => {
          this.freee.authorized = true
          this.freee.tokenExists = true
          this.checkToken()
          this.$store.commit('setOrganizationSetting', {
            freeeTokenExists: true,
          })
          Snackbar.success('freeeとの連携に成功しました。')
        })
        .catch((error) => {
          const errorBody = error.response.data
          if ('error' in errorBody) {
            Snackbar.error(errorBody.error)
          } else if ('error_description' in errorBody.data) {
            Snackbar.error(errorBody.data.error_description)
          }
          this.loading.page = false
        })
    },
    checkToken() {
      this.loading.page = true
      this.$store.dispatch('checkFreeeAccessToken').then((payload) => {
        if (payload.authorized) {
          this.freee.companyList = payload.companyList
          this.freee.authorized = true
        } else {
          Snackbar.error('freeeとの接続に失敗しました（トークンエラー）')
          this.removeFreeeDB()
          this.freee.authorized = false
        }
        this.loading.page = false
      })
    },
    fetchData(allowOverWrite) {
      const toggleLoading = (allowOverWrite, state) => {
        if (allowOverWrite) {
          this.loading.fetchDataReplace = state
        } else {
          this.loading.fetchData = state
        }
      }
      toggleLoading(allowOverWrite, true)
      Axios.post(
        this.$store.getters.apiFreeeMasterUrl,
        {
          company_accounting: this.checkbox.account_title,
          company_id: this.freee.companyId,
          department: this.checkbox.department,
          item: this.checkbox.item,
          organization_id: this.$store.getters.getOrganizationID,
          segment: this.checkbox.segment,
          tag: this.checkbox.tag,
          tax: this.checkbox.tax,
          user_organization_id: this.$store.getters.getUserOrganizationID,
          vendor: this.checkbox.vendor,
          walletable: this.checkbox.walletable,
          allowOverWrite: allowOverWrite,
        },
        { headers: { Authorization: this.$store.getters.getAuthToken } }
      ).then((response) => {
        if ('errors' in response.data) {
          const errors = response.data.errors
          const selectedMasters = Object.keys(this.checkbox).filter(
            (key) => this.checkbox[key]
          )
          const segmentError =
            errors.length === 1 && errors[0].error === 'get segment error'
          if (selectedMasters.length > 1 && segmentError) {
            Snackbar.warning(
              'セグメントを取得する権限がありません。セグメントの同期に失敗しました。'
            )
          } else {
            const messages = errors.map((e) => e.message)
            Snackbar.error(messages.join('\n'))
          }
          toggleLoading(allowOverWrite, false)
        } else {
          this.getJournalItems()
          Snackbar.success('同期が完了しました')
          this.dialog.checkOverwrite = false
        }
        toggleLoading(allowOverWrite, false)
      })
    },
    switchFreeeConnection(on) {
      if (on) {
        this.getAuthorization()
      } else {
        this.removeFreeeDB().then((succeeded) => {
          if (succeeded) {
            Snackbar.success('連携解除に成功しました')
          } else {
            Snackbar.error('連携解除に失敗しました')
          }
        })
      }
      this.freee.authorized = on
    },
    async getAuthorization() {
      this.loading.getAuthorization = true
      const response = await Axios.get(
        this.$store.getters.apiFreeeAuthorizationUrl,
        {
          headers: { Authorization: this.$store.getters.getAuthToken },
        }
      ).catch((error) => error.response)
      if ('error' in response.data) {
        Snackbar.error(response.data.error)
        this.loading.getAuthorization = false
      } else {
        this.freee.authorized = true
        window.location.href = response.data.authorization_uri
      }
    },
    async removeFreeeDB() {
      return await Axios.delete(this.$store.getters.apiFreeeTokenUrl, {
        headers: { Authorization: this.$store.getters.getAuthToken },
        params: { organization_id: this.$store.getters.getOrganizationID },
      })
        .then(() => true)
        .catch(() => false)
    },
    activateGetMasterDialog() {
      const enableGetMaster = () => {
        if (!this.freee.tokenExists) {
          Snackbar.error('トークンが存在しません。認証をしてください')
          return
        }
        if (!this.freee.companyId) {
          Snackbar.error('連携事業所を選択してください')
          return
        }
        return true
      }
      this.dialog.checkOverwrite = enableGetMaster()
    },
    changeApproveType(type) {
      this.$store
        .dispatch('postFreeeApprovalType', type)
        .then((success) => {
          if (success) {
            Snackbar.success('仕訳同期方法を変更しました。')
            const freeeSeparateApproval = {
              freeeSeparateApproval: type,
            }
            this.$store.commit('setOrganizationSetting', freeeSeparateApproval)
          }
        })
        .catch((error) => {
          Snackbar.error(error.message)
        })
    },
    fetchCompaniesWithFreeeData() {
      this.loading.learn = true
      freeeCompanyService
        .get(this.$store.getters.getAuthToken)
        .then((companies) => {
          const convertedCompanies = companies.map((company) =>
            convertForImportWidget(company)
          )
          this.$set(this, 'companiesFromFreee', convertedCompanies)
          this.dialog.importWidget = true
          this.loading.learn = false
        })
        .catch((error) => {
          Snackbar.error(error.message)
          this.loading.learn = false
        })
    },
    async createCompaniesWithFreeeData(companies) {
      this.loading.importWidget = true
      try {
        await companyBatchService.bulkCreate(
          this.$store.getters.getAuthToken,
          companies
        )
        this.loading.importWidget = true
        this.dialog.importWidget = false
        Snackbar.success('学習データを作成しました。')
      } catch (error) {
        Snackbar.error('学習データを作成できませんでした。')
      }
    },
  },
}
</script>
