<template>
  <v-data-table
    fixed-header
    show-select
    :headers="computedHeaders"
    :items="companies"
    :options.sync="options"
    :footer-props.sync="footerProps"
    :custom-sort="customSort"
    item-key="company_name"
  >
    <template #top>
      <slot name="top" />
    </template>

    <template #header.data-table-select="{}">
      <select-all-header-cell
        @click:item="$emit('multiselect', $event.value)"
      />
    </template>
    <template #item.data-table-select="{ item }">
      <v-simple-checkbox v-model="item.selected" />
    </template>

    <template #item.company_name="{ item }">
      {{ item.company_name }}
    </template>

    <template #item.bank_name="{ item }">
      {{ item.bank_name }}<br /><span v-if="item.bank_code" class="fw-normal"
        >({{ item.bank_code }})</span
      >
    </template>

    <template #item.bank_branch_name="{ item }">
      {{ item.bank_branch_name }}<br /><span
        v-if="item.bank_branch_code"
        class="fw-normal"
        >({{ item.bank_branch_code }})</span
      >
    </template>

    <template #item.user_bank="{ item }">
      {{ item.user_bank.simpletext }}
    </template>

    <template #item.paid_by_customer="{ item }">
      <v-chip
        v-show="item.paid_by_customer"
        label
        x-small
        dark
        color="blue"
        v-text="'先方'"
      />
    </template>

    <template #item.gensenzei="{ item }">
      <s-chip-dark v-show="item.gensenzei" color="blue-500" class="px-3">
        源泉
      </s-chip-dark>
    </template>

    <template #item.journals="{ item }">
      <journal-cell
        :items="item.company_pattern.company_journals"
        :show-all="item.showAll"
        :definition="definition"
        @click:show-all="item.showAll = !item.showAll"
      />
    </template>

    <template #no-data>
      <v-alert :value="true" color="yellow" icon="warning" class="mt-3">
        {{ $t('message.noResult') }}
      </v-alert>
    </template>
  </v-data-table>
</template>

<script>
import SelectAllHeaderCell from 'Components/Table/SelectAllHeaderCell'
import JournalCell from 'Components/Table/JournalCell'

export default {
  components: {
    SelectAllHeaderCell,
    JournalCell,
  },
  props: {
    companies: {
      type: Array,
      required: true,
    },
    importFromFreee: {
      type: Boolean,
      default: false,
    },
    definition: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      options: {
        page: 1,
        itemsPerPage: 20,
      },
      footerProps: {
        'items-per-page-text': '表示件数',
        'items-per-page-options': [20, 50, 100],
      },
      tableHeaders: [
        {
          text: this.$t('message.company_table_company_name'),
          value: 'company_name',
          width: '250px',
          display: true,
        },
        {
          text: this.$t('message.company_table_company_address'),
          value: 'company_address',
          display: true,
        },
        {
          text: this.$t('message.company_table_company_phonenumber'),
          value: 'company_phonenumber',
          display: true,
        },
        {
          text: '仕訳',
          value: 'journals',
          width: '200px',
          display: true,
        },
        {
          text: 'メールアドレス',
          value: 'email',
          display: true,
        },
        {
          text: this.$t('message.bank_name'),
          value: 'bank_name',
          display: true,
        },
        {
          text: this.$t('message.bank_branch_name'),
          value: 'bank_branch_name',
          display: true,
        },
        {
          text: this.$t('message.bank_account_type'),
          value: 'bank_account_type',
          display: true,
        },
        {
          text: this.$t('message.bank_account_number'),
          value: 'bank_account_number',
          display: true,
        },
        {
          text: this.$t('message.bank_account_name'),
          value: 'bank_account_name',
          display: true,
        },
        {
          text: '支払方法',
          value: 'pay_type_name',
          display: true,
        },
        {
          text: this.$t('message.company_table_pay_site'),
          value: 'pay_site_name',
          display: true,
        },
        {
          text: '振込元口座',
          value: 'user_bank',
          display: true,
        },
        {
          text: '手数料負担',
          value: 'paid_by_customer',
          display: true,
        },
        {
          text: this.$t('message.company_table_gensenzei'),
          value: 'gensenzei',
          display: true,
        },
        {
          text: '源泉の区分',
          value: 'gensen_code',
          display: true,
        },
        {
          text: '源泉の対象人数',
          value: 'gensen_total_targets',
          display: true,
        },
      ],
    }
  },
  computed: {
    computedHeaders: function () {
      if (!this.$store.getters.settingPaidByCustomer) {
        const column = this.tableHeaders.find(
          (column) => column.value === 'paid_by_customer'
        )
        column.display = false
      }
      return this.tableHeaders.filter((h) => h.display)
    },
    journalTableProps() {
      const objects = [
        {
          text: '勘定科目',
          value: 'account_title',
        },
        {
          text: '補助科目',
          value: 'sub_account_title',
        },
        {
          text: '部門',
          value: 'department',
        },
        {
          text: '取引先',
          value: 'vendor',
        },
        {
          text: 'プロジェクト',
          value: 'project',
        },
        {
          text: 'セグメント',
          value: 'segment',
        },
        {
          text: '品目',
          value: 'item',
        },
        {
          text: 'タグ',
          value: 'tag',
        },
        {
          text: '口座',
          value: 'walletable',
        },
        {
          text: '税区分',
          value: 'tax',
        },
      ]
      const [headers, bodyAttrs] = [[], []]
      for (const object of objects) {
        headers.push('借方' + object.text)
        bodyAttrs.push('debit_' + object.value)
      }
      if (!this.importFromFreee) {
        for (const object of objects) {
          headers.push('貸方' + object.text)
          bodyAttrs.push('credit_' + object.value)
        }
      }
      headers.push('摘要')
      bodyAttrs.push('description')
      return { headers, bodyAttrs }
    },
  },
  methods: {
    customSort: function (items, index, isDesc) {
      items.sort((a, b) => {
        if (index == 'user_bank') {
          a.userBankID = a.user_bank.id ? a.user_bank.id : 0
          b.userBankID = b.user_bank.id ? b.user_bank.id : 0
          if (!isDesc[0]) {
            return a.userBankID < b.userBankID ? -1 : 1
          } else {
            return b.userBankID < a.userBankID ? -1 : 1
          }
        } else {
          if (!isDesc[0]) {
            return a[index] < b[index] ? -1 : 1
          } else {
            return b[index] < a[index] ? -1 : 1
          }
        }
      })
      return items
    },
  },
}
</script>
