import APIClient from '@/clients/_helpers/client'
import URLs from '@/clients/_urls'

class CompaniesByFreee extends APIClient {
  async get(token) {
    const getErrorMessage = (error) => {
      const errorBody = error.response.data.error
      const errorToMessage = {
        AuthorizationFailure:
          'freee認証エラーが発生しました。サポートチームにお知らせください。',
        NoCompanyToCreate:
          '作成可能なデータがありません。\n一年間の間に取引が行われていない場合、同一取引先(会社名 + 電話番号)のデータは再度作成されません。',
        Undefined: 'サポートチームにお知らせください。',
      }
      if (errorBody in errorToMessage) {
        return errorToMessage[errorBody]
      } else {
        const errorMessages = errorBody.map((e) => {
          if (e.match('CompanyAccountTitle')) {
            return 'freeeの勘定科目を同期してください。'
          } else if (e.match('Vendor')) {
            return 'freeeの取引先を同期してください。'
          } else if (e.match('TaxClassification')) {
            return 'freeeの税区分を同期してください。'
          }
        })
        return errorMessages.join('\n')
      }
    }
    const payload = {
      url: URLs.COMPANIES_BY_FREEE,
      method: 'GET',
      headers: {
        Authorization: token,
      },
    }
    try {
      const response = await this.sendRequest(payload)
      return response.data.companies
    } catch (error) {
      throw new Error(getErrorMessage(error))
    }
  }
}
export default new CompaniesByFreee()
