var companyTypeList = [
  '株式会社',
  '㈱',
  '(株)',
  '有限会社',
  '㈲',
  '(有)',
  '合同会社',
  '(同)',
  '合資会社',
  '(資)',
  '合名会社',
  '(名)',
  '医療法人',
  '(医)',
  '財団法人',
  '(財)',
  '一般財団法人',
  '(一財)',
  '公益財団法人',
  '(公財)',
  '社団法人',
  '(社)',
  '一般社団法人',
  '(一社)',
  '公益社団法人',
  '(公社)',
  '宗教法人',
  '(宗)',
  '学校法人',
  '(学)',
  '社会福祉法人',
  '(福)',
  '社会福祉法人',
  '(福)',
  '更生保護法人',
  '相互会社',
  '特定非営利活動法人',
  '(特非)',
  '独立行政法人',
  '(独)',
  '地方独立行政法人',
  '(地独)',
  '弁護士法人',
  '(弁)',
  '有限責任中間法人',
  '無限責任中間法人',
  '(中)',
  '行政書士法人',
  '(行)',
  '司法書士法人',
  '(司)',
  '税理士法人',
  '(税)',
  '国立大学法人',
  '公立大学法人',
  '(大)',
  '農事組合法人',
  '管理組合法人',
  '社会保険労務士法人',
  '監査法人',
  '連合会',
  '(連)',
  '共済組合',
  '(共済)',
  '協同組合',
  '(協組)',
  '生命保険',
  '(生命)',
  '海上火災保険',
  '(海上)',
  '火災海上保険',
  '(火災)',
  '健康保険組合',
  '(健保)',
  '国民健康保険組合',
  '(国保)',
  '国民健康保険団体連合会',
  '(国保連)',
  '社会保険診療報酬支払基金',
  '(社保)',
  '厚生年金基金',
  '(厚年)',
  '従業員組合',
  '(従組)',
  '労働組合',
  '(労組)',
  '生活協同組合',
  '(生協)',
  '食糧販売協同組合',
  '(食販協)',
  '国家公務員共済組合連合会',
  '(国共連)',
  '農業協同組合連合会',
  '(農協連)',
  '経済農業協同組合連合会',
  '(経済連)',
  '共済農業協同組合連合会',
  '(共済連)',
  '漁業協同組合',
  '(漁協)',
  '漁業協同組合連合会',
  '(漁連)',
  '公共職業安定所',
  '(職安)',
  '社会福祉協議会',
  '(社協)',
  '特別養護老人ホーム',
  '(特養)',
  '有限責任事業組合',
  '(責)',
]

const companyTypeAfJoin = companyTypeList
  .map((v) => v.replace(/\(/g, '[(（]').replace(')', '[)）]'))
  .join('|')
const abbreviationString = `(?:${companyTypeAfJoin})`
const startWithAbbreviation = new RegExp(`^${abbreviationString}`)
const endWithAbbreviation = new RegExp(`${abbreviationString}$`)

export const checkHasAbbreviation = (companyName) => {
  return (
    startWithAbbreviation.test(companyName) ||
    endWithAbbreviation.test(companyName)
  )
}
