<template>
  <v-dialog v-model="dialog" fullscreen scrollable>
    <v-card>
      <v-stepper v-model="stage" vertical style="overflow-y: scroll">
        <v-stepper-step
          :complete="stage > widgetStage.startInteraction"
          :step="widgetStage.startInteraction"
        >
          借方勘定科目が入力されていないデータは学習データとして不十分なため除去されました。<br /><br />
          同一取引先（会社名＋電話番号）で複数のデータが存在する場合、インポートファイルの行数で一番若いデータのみ取り込まれます。<br /><br />
          続けて学習データのスクリーニングを行ってください。
        </v-stepper-step>
        <v-stepper-content :step="widgetStage.startInteraction">
          <v-btn
            color="primary"
            @click="stage = widgetStage.filterDebitAccountTitle"
          >
            次へ
          </v-btn>
          <v-btn text @click="dialog = false"> キャンセル </v-btn>
        </v-stepper-content>

        <v-stepper-step
          :complete="stage > widgetStage.filterDebitAccountTitle"
          :step="widgetStage.filterDebitAccountTitle"
        >
          学習対象の借方勘定科目を指定してください。<br /><br />
          この操作は（主に会社払いの）経費と無関係の勘定科目を除去することで余計な学習データを作成しないために行います。
          例えば経費に関連しない学習データ（売上先など）を登録すると仕訳自動起票の妨げになります。
        </v-stepper-step>

        <v-stepper-content :step="widgetStage.filterDebitAccountTitle">
          <v-card class="mb-12">
            <v-autocomplete
              ref="debitAccountTitles"
              v-model="selectedAccountTitles"
              v-tooltip="
                'ボックスをクリックする事で、CSVに記入された全ての借方科目を表示することができます。'
              "
              class="pa-4"
              :items="debitAccountTitles"
              :item-text="(obj) => obj.name + obj.code"
              item-value="id"
              return-object
              chips
              multiple
            >
              <template #append>
                <v-icon size="36">
                  {{
                    $refs.debitAccountTitles.isMenuActive
                      ? 'mdi-menu-up'
                      : 'mdi-menu-down'
                  }}
                </v-icon>
              </template>
              <template #selection="{ item }">
                <v-chip
                  color="primary"
                  outlined
                  close
                  @click:close="remove(item)"
                >
                  {{ `${item.name} ${item.code}` }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-card>
          <v-btn color="primary" @click="toTable()"> 次へ </v-btn>
          <v-btn text @click="stage--"> 戻る </v-btn>
          <v-btn text @click="dialog = false"> キャンセル </v-btn>
        </v-stepper-content>

        <v-stepper-step
          :complete="stage > widgetStage.filterFromTable"
          :step="widgetStage.filterFromTable"
        >
          <b>スクリーニング最終確認</b><br />
          学習しないデータはチェックオフにし、インポートボタンを押してください。
          初期学習データは確実に利用するもののみをインポートすることをお勧めしております。<br /><br />
          ＜スクリーニング方法例＞
          ・従業員の立替経費で使用している取引先（従業員名）および仕訳を除外
          ・口座番号のない取引先は仕入先ではないので除外
          ・電話番号がある取引先のみを登録（それ以外を除外 →
          仕訳自動起票の確率が低下することがあります
        </v-stepper-step>

        <v-stepper-content :step="widgetStage.filterFromTable">
          <v-card class="mb-12">
            <import-widget-table
              ref="widgetTable"
              :companies="filteredCompanies"
              :import-from-freee="importFromFreee"
              :definition="$store.getters.definition"
              @multiselect="onClickSelectMultiple($event)"
            >
              <template v-slot:top>
                <v-row no-gutters>
                  <v-checkbox
                    v-model="shouldFilterByCompanyName"
                    label="法人以外"
                  />
                  <v-checkbox
                    v-model="shouldFilterByPhone"
                    label="電話番号なし"
                  />
                  <v-checkbox
                    v-model="shouldFilterByBankNumber"
                    label="口座番号なし"
                  />
                </v-row>
              </template>
            </import-widget-table>
          </v-card>
          <v-btn
            color="primary"
            :loading="loading"
            :disabled="selectedCompanies.length === 0"
            @click="submitImport()"
          >
            インポート
          </v-btn>
          <v-btn text @click="stage--"> 戻る </v-btn>
          <v-btn text @click="dialog = false"> キャンセル </v-btn>
        </v-stepper-content>
      </v-stepper>
    </v-card>
  </v-dialog>
</template>
<script>
import ImportWidgetTable from '@/pages/master/Company/@components/ImportWidget/ImportWidgetTable/ImportWidgetTable'

import { checkHasAbbreviation } from 'Helpers/table/filter/checkHasAbbreviation'
import { applyMultiSelect } from 'Helpers/table'

export default {
  components: {
    ImportWidgetTable,
  },

  props: {
    companies: {
      type: Array,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    importFromFreee: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      stage: 1,
      shouldFilterByPhone: false,
      shouldFilterByBankNumber: false,
      shouldFilterByCompanyName: false,
      widgetStage: {
        startInteraction: 1,
        filterDebitAccountTitle: 2,
        filterFromTable: 3,
      },
      selectedAccountTitles: [],
    }
  },

  computed: {
    dialog: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('change:show', val)
      },
    },
    filteredCompanies() {
      const {
        shouldFilterByPhone,
        shouldFilterByBankNumber,
        shouldFilterByCompanyName,
      } = this
      const filterFunction = (row) => {
        if (
          !(
            shouldFilterByPhone ||
            shouldFilterByBankNumber ||
            shouldFilterByCompanyName
          )
        ) {
          return true
        }
        if (shouldFilterByPhone && !row.company_phonenumber) {
          return true
        }
        if (shouldFilterByBankNumber && !row.bank_account_number) {
          return true
        }
        return (
          shouldFilterByCompanyName && !checkHasAbbreviation(row.company_name)
        )
      }
      return this.companies.filter(
        (company) => company.display && filterFunction(company)
      )
    },
    selectedCompanies() {
      return this.filteredCompanies.filter((company) => company.selected)
    },
    debitAccountTitles() {
      const debitAccountTitleSet = new Set()
      const accountTitleList = []
      for (const company of this.companies) {
        const companyJournals = company.company_pattern.company_journals
        for (const journal of companyJournals) {
          if (!debitAccountTitleSet.has(journal.debit_account_title.id)) {
            debitAccountTitleSet.add(journal.debit_account_title.id)
            accountTitleList.push(journal.debit_account_title)
          }
        }
      }
      return accountTitleList
    },
  },
  watch: {
    dialog: function (on) {
      if (on) {
        this.$set(
          this,
          'selectedAccountTitles',
          JSON.parse(JSON.stringify(this.debitAccountTitles))
        )
      } else {
        this.stage = this.widgetStage.startInteraction
      }
    },
  },
  methods: {
    remove(accountTitle) {
      const index = this.selectedAccountTitles.findIndex(
        (obj) => obj.id === accountTitle.id
      )
      this.selectedAccountTitles.splice(index, 1)
    },
    toTable() {
      const selectedIdSet = new Set(
        this.selectedAccountTitles.map((obj) => obj.id)
      )
      for (const company of this.companies) {
        company.display = true
        const companyJournals = company.company_pattern.company_journals
        for (const journal of companyJournals) {
          const debitAccountTitleId = journal.debit_account_title
            ? journal.debit_account_title.id
            : null
          const hasId = selectedIdSet.has(debitAccountTitleId)
          if (!hasId) {
            company.display = false
            break
          }
        }
      }
      this.stage = this.widgetStage.filterFromTable
    },
    async submitImport() {
      const message = `${this.selectedCompanies.length}件の学習データをインポートしますがよろしいでしょうか？
      すでに同一取引先（会社名＋電話番号）の学習データが存在する場合は上書き登録されません。（同学習データは破棄されます）`
      const yes = await this.$confirm({ message })
      if (!yes) {
        return
      }
      this.$emit('click:import', this.selectedCompanies)
    },
    onClickSelectMultiple(type) {
      const selectFuncFactory = (bool) => {
        return (companyList) => {
          for (const company of companyList) {
            company.selected = bool
          }
        }
      }
      applyMultiSelect(type, this.filteredCompanies, {
        ...this.$refs.widgetTable.options,
        selectFunction: selectFuncFactory(true),
        disSelectFunction: selectFuncFactory(false),
      })
    },
  },
}
</script>
